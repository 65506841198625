<template>
  <div class="policy-detail">
    <div class="banner">
      <img src="../assets/images/zhengcejiansuo.jpg" alt="" width="100%">
    </div>
    <div class="content">
      <div class="title">{{result.title}}</div>
      <el-divider></el-divider>
      <div class="intro">{{result.intro}}</div>
      <div class="text-content" v-html="result.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'policyDetail',
  props: ['id'],
  data () {
    return {
      result: {}
    }
  },
  methods: {
    getData () {
      this.API.getArticledetail({ id: this.id }).then(({ code, data }) => {
        this.result = data
      })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
  .policy-detail{
    .content{
      max-width: 1000px;
      margin: 20px auto;
      .title{
        color: #323534;
        font-size: 20px;
        line-height: 36px;
        font-weight: 600;
        margin-top: 10px;
      }
      .intro{
        text-align: center;
        font-weight: bold;
        color: #df0000;
      }
      .text-content{
        line-height: 40px;
        font-size: 16px;
        color: #333;
        text-align: left;
        margin-top: 20px;
      }
    }
  }

</style>
